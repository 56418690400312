<template>
    <el-dialog :close-on-click-modal="false" title="高级搜索" :visible.sync="show" width="500px" class="selfInputBox">
        <el-form v-if="formShow" ref="form" :model="value" label-width="80px">

            <el-form-item label="出库人" prop="title">
                <el-select v-model="value1" clearable placeholder="请选择出库人" class="SelfSelectI">
                    <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="所属医院" prop="title">
                <el-select v-model="value1" clearable placeholder="请选择所属医院" class="SelfSelectI">
                    <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="科室" prop="title">
                <el-select v-model="value1" clearable placeholder="请选择科室" class="SelfSelectI">
                    <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="出库时间" prop="title">
                <el-select v-model="value1" clearable placeholder="请选择出库时间" class="SelfSelectI">
                    <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="类型" prop="title">
                <el-select v-model="value1" clearable placeholder="请选择出类型" class="SelfSelectI">
                    <el-option v-for="item in options5" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="筛选项示例" prop="title">
                <el-select v-model="value1" clearable placeholder="新增筛选项" class="SelfSelectI">
                    <el-option v-for="item in options6" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>



        </el-form>
        <span slot="footer">
            <div class="buttons">
                <el-button style="width: 100px;" type="primary" @click="refresh">筛选</el-button>
                <el-button style="width: 100px;" @click="$refs.form.resetFields()">重置</el-button>
            </div>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                formShow: false,
                state: true,


                // 回显图片的路径
                photoList: [{
                    url: ""
                }],
                // 图片的路径
                dialogImageUrl: "",



                options1: [{
                    value: '选项1',
                    label: '张三'
                }, {
                    value: '选项2',
                    label: '李四'
                }],
                value1: '',

                options2: [{
                    value: '选项1',
                    label: '天津市双街镇社区卫生服务中心'
                }, {
                    value: '选项2',
                    label: '天津市西青区华苑日华里社区卫生服务中心'
                }],
                value2: '',

                options3: [{
                    value: '选项1',
                    label: '感染性'
                }, {
                    value: '选项2',
                    label: '放射性'
                }],
                value3: '',

                options4: [{
                    value: '选项1',
                    label: '内科一室'
                }, {
                    value: '选项2',
                    label: '实验楼一楼'
                }],
                value4: '',

                options5: [{
                    value: '选项1',
                    label: '今天'
                }, {
                    value: '选项2',
                    label: '昨天'
                }, {
                    value: '选项3',
                    label: '前天'
                }],
                value5: '',

                options6: [{
                    value: '选项1',
                    label: '今天'
                }, {
                    value: '选项2',
                    label: '昨天'
                }, {
                    value: '选项3',
                    label: '前天'
                }],
                value6: '',



            }
        },
        methods: {
            open() {
                this.formShow = true
                this.show = true
            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

            uploadSectionFile(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("111111" + file.url);
            },



            beforeRemove(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("22222" + file.url);
            },

            handlePreview(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("33333" + file.url);
            },




        }
    }
</script>

<style>
    .SelfSelectI {
        width: 200px;
    }

    .selfInputBox .SelfSelectI .el-input__inner {
        width: 90% !important;
    }
</style>